import React, { Component } from 'react';
import AppDetails from '../AppDetails/AppDetails';
import PropTypes from 'prop-types';
import appStore from '../../../images/app-store.svg';

class MainTitle extends Component {
	constructor(props) {
		super(props);
		// Don't call this.setState() here!
		this.state = { open: false };
	}

	handleClickOpen = () => {
		this.setState({ open: true })
	};

	handleClose = () => {
		this.setState({ open: false })
	};
	render() {
		const { alert, alertColor, title, description, titleColor, colorClass, linkTitle, linkColor, appStoreLink, sparked, skillr } = this.props;
		return (
			<div className="container">
				<div className={`new-alert ${alertColor}`}>
					{alert}
				</div>
				<div className={`title-wrapper bold ${titleColor}`}>
					{title}
				</div>

				<div className={`description-wrapper ${colorClass}`}>
					{description}
				</div>
				<div className={`links-wrapper ${linkColor}`}>
					<ul>
						{linkTitle ? <li><a href="top"
							onClick={(e) => {
								e.preventDefault();
								this.handleClickOpen();
							}}>{linkTitle}</a> </li>: null}
							{appStoreLink ? <MobileStoreButton
							store="ios"
							url={appStoreLink}
							linkProps={{ title: 'iOS Store Button' }}
						  /> : null}
					</ul>
				</div>
				<AppDetails
					open={this.state.open}
					handleClose={this.handleClose}
					sparked={sparked}
					skillr={skillr}>
				</AppDetails>
			</div>
		);
	}
}

export default MainTitle;

const imageLinks = {
	ios: appStore,
	android: 'https://play.google.com/intl/en_us/badges/images/generic/en_badge_web_generic.png',
  };
  
  class MobileStoreButton extends React.Component {
	static propTypes = {
	  store: PropTypes.oneOf(['ios', 'android']).isRequired,
	  url: PropTypes.string.isRequired,
	  height: PropTypes.number,
	  width: PropTypes.number,
	  linkStyles: PropTypes.object,
	  linkProps: PropTypes.object,
	};
	static defaultProps = {
	  height: 40,
	  width: 100,
	};
  
	render() {
	  const {
		store,
		url,
		height,
		width,
		linkStyles,
		linkProps,
		...props
	  } = this.props;
  
	  const defaultLinkStyles = {
		background: `url(${imageLinks[store]}) no-repeat`,
		backgroundSize: 'contain',
		display: 'inline-block',
		overflow: 'hidden',
		textDecoration: 'none',
		height: '100%',
		width: '100%',
		padding: '5px',
		...linkStyles,
	  };
  
	  return (
		<div style={{ height, width, display: 'inline-block' }} {...props}>
		  <a
			style={defaultLinkStyles}
			href={url}
			target="_blank"
			rel="noopener noreferrer"
			{...linkProps}
		  >
			&nbsp;
		  </a>
		</div>
	  );
	}
  }