import React, { Component } from 'react';
import './AppDetails.css';
var crash = require('../../../images/projects/sparked/crash.png');

function Sparked() {
    return (
        <div className="about">
            <h1>The Story</h1>
            <p>There's a saying in college that you can only choose two of three things; social life, sleep, or studies.
                I naturally gravitated towards the first two as school always came easy to me. Then I was able to
                walk-on to the basketball team and between practice, games, and workouts my studies slipped even harder.
                It didn't help that I was majoring in Electrical Engineering at the time and those prerequisite classes
                were no joke so while my classmates were hitting the books, I was catching up on sleep or out and about
                with my friends. My priorities weren't in order, but I was 18. Sue me. All of that
                struggle was worth it because one of those prerequisite classes that I failed introduced me into this
                magical world of coding. After two years of this incredibly fun lifestyle, I decided to quit the team
                and began shifting my focus to coding.
            </p><br></br>
            <p>The Computer Science courses were great for getting through the
                basics but I was naturally drawn to iPhones and the apps running on them and wanted to learn how to
                build them. Now what to build? I recalled my struggles from earlier and figured there was no way in
                hell I was the only person struggling to keep up with classes and there's probably some who can't even
                make it to classes due to work, family, commuting, etc. The only resources I was able to find that
                were class specific were Facebook groups which needed to be created by a student and at this point, who 
                the hell was even still using Facebook, and Blackboard🤢. What we needed was a one stop shop for interacting 
                with other students and staying up to date with your courses. And so SparkEd was born!
            </p><br></br>
            <h1>Building the App</h1>
            <h3>iOS pt. 1</h3>
            <p>I dove headfirst into iOS development using Swift and storyboards and fell in love with it. With the help
                of all the resources available on the internet (HUGE thank you to youtubers Brian Voong (Lets Build
                That App) and Sean Allen), I was able to build out an MVP. I used Firebase to work with live data but
                felt like I was cheating the system a little bit not understand what was going on under the hood with
                my data. So the Mamba Mentality in me said that I should build out my backend from scratch.
            </p><br></br>
            <h3>Backend</h3>
            <p>I watched and followed along with a 4 hour youtube tutorial on how to build a social network app using
                the MEAN stack. Took the knowledge I gained from that and RAN with it. Error handling, AWS S3 image/video
                storing, Redis caching, RabbitMQ for microservices, sending emails, logging, load testing, Nginx, the
                list could go on but this was powerful stuff to learn and thank the heavens I wasn't afraid to get dirty with
                the backend building because I now wholeheartedly believe I can debug/build ANY application given enough time.
            </p>
            <h3>iOS pt. 2</h3>
            <p>I didn't touch my original iOS code for a while as I was still in school while learning how to build out the
                backend. When I came back to it, with my growth as a programmer looked at the organization and was not happy. Confusing storyboards, spaghetti code, massive view controller files, Firebase functions that I couldn't follow all over the place, and just an ugly interface in general. I shelved the code and decided to build out the app from scratch with zero storyboards. These outlandish decisions
                to drop everything and build from scratch are hilarious in hindsight but I am literally infinitely better as a
                developer now because of them. Because of the work I was doing/able to do on the backend I was able to take care of
                things that I believe are essential to having a good user experience on any mobile app such as error handling and
                push notifications.
            </p><br></br>
            <blockquote><p>"If you are not embarrassed by the first version of your product, you've launched too late." - Reid Hoffman</p>
            </blockquote>
            <p>I sent out a link to family and friends and they played with it a little bit but there were
                a number of problems that I didn't think about as well as one major problem that actually stopped me from taping posters
                on campus telling people to download it. For one, there was nothing to keep a user on the app. It was literally just another
                Twitter with less features. How in the world am I going to retain users if I can't even get my friend posting? I resolved this
                by bulding out a groups functionality for fraternities, clubs, and sports teams and also built out a calendar with on campus events
                but got hired full-time before I could even push that version out. Hopefully will get back to that code by the time you're viewing
                my profile.
            </p><br></br>
            <h4>The major problem: A crash</h4>
            <img src={crash} alt="Screenshot of comments crash" className="image" />
            <p>One of my friends posted a comment that I liked/unliked multiple times and then he commented again, "Crashed".
                That word is and should be a scary one for all developers. Bugless code is simply not possible doesn't matter how much you try to
                stop it. But this crash was inevitable. I had a microservice that handled creating/deleting notifications that would pop up on the feed
                and for some reason, my RabbitMQ instance did not send out the message to this microservice for deleting the like
                notification. My reasoning for using a microservice for this was that this is all extra work that the user does not care about when
                they tap to like a post. They just want to successfully like the post as fast as possible (API Response time). Cascading delete
                with Relational databases would've solved this whole issue so I created myself a task that is now 2 and a half years on standby to
                migrate my data and switch my database from MongoDB to MySQL.
            </p><br></br>
            <h1>Tech Used</h1>
        </div>
    );
}

export default Sparked;