import React, { Component } from 'react';

import Welcome from "./Welcome/Welcome";
import Skillr from "./Skillr/Skillr";
import FeaturedApp from "./FeaturedApp/FeaturedApp";
import Projects from "./Projects/Projects";
import Tech from "./Tech/Tech";
// import Youtube from '../Youtube/Youtube';


class Main extends Component {
  render() {
    return (
      <div>
        <Welcome />
        <Skillr />
        <FeaturedApp />
        <Projects />
        <Tech />
      </div>
    );
  }

}

export default Main;