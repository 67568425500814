import React, { Component } from 'react';
import './Projects.css';
import SecondTitle from '../Titles/SecondTitle';

class Projects extends Component {
    render() {
        return (
            <section className="projects-wrapper">
                <div className="container-fluid">
                    <div className="row">
                        <SecondTitle wrapperClass="left-side-wrapper"
                            innerClassName="left-side-container" titleColor="black"
                            title="Pretty Useful"
                            description="Tools for making day-to-day tasks easier."
                            colorClass="black"
                            link="project"
                            linkColor="black"
                            hidden="false"
                            app={true} />
                        {/* <StructureTwo wrapperClass="left-side-wrapper" 
                            innerClassName="left-side-container"
                            title= "Pretty Useful" description="Various useful tools for enhancing day to day efficiency."/> */}
                        <SecondTitle wrapperClass="right-side-wrapper"
                            innerClassName="right-side-container" titleColor="white"
                            title="GXP OnScene"
                            description="Real-time command to first responders in the field."
                            colorClass="white"
                            link="project"
                            linkColor="white"
                            hidden="false"
                            app={false} />
                    </div>
                </div>
            </section>
        );
    }
}

export default Projects;