import React, {Component } from 'react';
import MainTitle from "../Titles/MainTitle";
import './Skillr.css'

class Skillr extends Component {
   
    render() { 
        return ( 
            <section className="skillr-app-wrapper">
               <MainTitle alert="Start Up" alertColor="skillr" titleColor= "white" title="Skillr"
			    colorClass="white" description="1-on-1 video chat with experts." linkTitle="Learn More" linkColor="skillr" hidden="false" skillr="true"
				/>
	        </section>
         );
    }
}

export default Skillr;
