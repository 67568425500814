import React, {Component } from 'react';
import MainTitle from "../Titles/MainTitle";
import './FeaturedApp.css'

class FeaturedApp extends Component {
   
    render() { 
        return ( 
            <section className="featured-app-wrapper">
               <MainTitle alert="Passion Project" alertColor="sparked" titleColor= "black" title="SparkEd"
			    colorClass="black" description="Social network centered around the college campus life." linkTitle="Learn More" linkColor="sparked" hidden="false" sparked="true"
				/>
	        </section>
         );
    }
}

export default FeaturedApp;
