import React, { Component } from 'react';
import './AppDetails.css';
// import directions from "../../../images/directions.png";

function OnScene() {
    return (
        <div className="about">
            <h1>The Job</h1>
            <p>My first full time job as an iOS Developer was working on the GXP OnScene mobile app (<a href="https://www.geospatialexploitationproducts.com/content/gxp-onscene/" target="_blank" rel="noopener noreferrer">Details</a>). Worked in an Agile environment on a small team (Less than 5 people). I was mainly tasked with designing and building out new functionality that was planned by the Product Manager/Product Owner, but they did a fantastic job of making sure that I had a voice that was heard on important design decisions which made the work very smooth to complete. The codebase was mainly Objective-C and resolving bugs on the iOS and Android versions of the app.
            </p><br></br>
            <h1>The App</h1>
            <p>GXP OnScene is a map focused application that allowed me to get intimately familiar with iOS MapKit, but I also was able to add some new pages that allowed users to view and manage data from the web client that they were previously not able to see. I also pitched the idea of integrating Firebase Crashltyics into the applications so that we could get more detailed insight into the crashes occurring in our app in the wild which everyone was on board with. This played a key role in helping us debug a few high priority bugs that came up from our customer support team. I also was able to challenge myself and complete a number of Android development tasks. Here are just a few examples of features I was able to build:
            </p><ul className="list">
                <li>Added forgot password functionallity which utilized Universal links to open the app from email.</li>
                <li>Improved map rendering performance by implementing clustering of map annotations.</li>
                <li>Added the ability to draw out and measure a polyline or polygon.</li>
                <li>Built out new Files page that retrieves files (Images, Videos, PowerPoints, Documents) from server.</li>
                <li>Improve account management by allowing user to save multiple credentials to different servers.</li>
            </ul>
            <br></br>
            <h1>Tech Used</h1>
        </div>
    );
}

export default OnScene;