import React, { Component } from 'react';
import './Tech.css'
// import Youtube from '../Youtube/Youtube';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import Grid from '@mui/material/Grid';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

var ios = require('../../../images/tech/iosdev.png');
var aws = require('../../../images/tech/aws.png');
var firebase = require('../../../images/tech/firebase.png');
var node = require('../../../images/tech/node.png');
var mongo = require('../../../images/tech/mongo.png');
var digitalocean = require('../../../images/tech/digitalocean.png');
var rabbitmq = require('../../../images/tech/rabbitmq.png');
var redis = require('../../../images/tech/redis.png');
var mysql = require('../../../images/tech/mysql.png');
var frontend = require('../../../images/tech/frontend.png');
var android = require('../../../images/tech/android.png');

const techArray = [
  { name: "iOS", image: ios, details: "iOS development is my bread and butter. From building UIs with or without storyboards, communicating data back and forth between a server, viewing/playing with photos and video files from the photo library, downloading files to local storage, etc. If it's possible on an iOS device, I can build it." },
  { name: "AWS", image: aws, details: "First experience with AWS was connecting my Node.js backend to S3 to store image and video files for my passion project, SparkEd.\nI also had to do some work to maintain a Parse server and debug a random MySQL database instance while working part-time in 2019." },
  { name: "Firebase", image: firebase, details: "Once I was able to put together a table view and some buttons on an iPhone screen, I immediately wanted to work with some live data. And Firebase was the quickest and easiest solution to working with live data in Swift that I could find.\nI wholeheartedly believe in Crashlytics for viewing details about crashes and have implemented it into each of the apps I have worked on so far." },
  { name: "Node.js", image: node, details: "I wasn't happy with the lack of control working with Firebase gave me over handling situations such as error handling and I just wasn't comfortable with not understanding exactly what was happening with my data from start to finish. So, in typical Kibret fashion... I decided to build out my back-end from scratch.\nWorking on building out the routes, designing and connecting the database, working with live data, load testing, securing the routes, etc. It all made me feel like I can actually build out anything that I can conceive and I love that feeling." },
  { name: "MongoDB", image: mongo, details: "I decided to build SparkEd on top of MongoDB as it was the first database I learned to use when learning how to build out a Node.js/Express backend.\nWithin a week of releasing to friends and family, I encountered an issue with my database integrity (Hacky cascade delete). I knew building a social app using a non-relational database was going to bite me at some point but nonetheless forever grateful for learning MongoDB."  },
  { name: "Deployment", image: digitalocean, details: "I chose Digital Ocean to host my backend server intances for SparkEd. I was very close to going all in on back-end development because diving into the whole server rabbit whole and implementing things like rate limiting and load balancing was extremely challenging and beyond rewarding."  },
  { name: "RabbitMQ", image: rabbitmq, details: "I used RabbitMQ to separate the extra work that the server needed to do into different microservices.\nThe first place I saw the use for it was with handling the sending of push notifications (Think of what happens when liking a user's post on Instagram). The work to search for the author of the post, search for their devices, build out the notification content, and then deliver to the user and log any errors is all work end users should not have to be waiting for."  },
  { name: "Redis", image: redis, details: "Introduced caching when a large amount of API calls were failing during load testing. The route itself was returning data that was not going to be changing very often (courses for a semester), so I figured it was a perfect route to cache and it was."  },
  { name: "MySQL", image: mysql, details: "I am yet to build anything from scratch using MySQL so I am not too comfortable interfacing with it. But with zero guidance and no documentation, I was able to connect to a MySQL database hosted on AWS which was needed to debug an issue that was occuring in the Parse server.\nI believe I will be getting very comfortable with MySQL when I get to the long put off task of migrating and refactoring my SparkEd database from MongoDB to MySQL."  },
  { name: "Front-End", image: frontend, details: "I've put together a few small pages using a number of different frameworks. I actually challenged myself to build this website using React. How does it look?"  },
  { name: "Android", image: android, details: "While I am primarily an iOS developer, I ultimately strive to one day be capable of building any application on any device. Asked for and was tasked with completing a few Android tasks in Java while working on GXP OnScene."  },
];

function GridItem({ tech, onClick }) {
  return (
    // From 0 to 600px wide (smart-phones), I take up 12 columns, or the whole device width!
    // From 600-690px wide (tablets), I take up 6 out of 12 columns, so 2 columns fit the screen.
    // From 960px wide and above, I take up 25% of the device (3/12), so 4 columns fit the screen.
    <Grid item xs={6} sm={4} md={3}>
      <Paper className='paper' elevation={1} onClick={onClick}>
        <Stack className='stack' spacing={0}>
          <span><img src={tech.image} alt={tech.name} /></span>
          <span>{tech.name}</span>
        </Stack>
      </Paper>
    </Grid>
  );
}

function NewlineText(props) {
  const text = props.text;
  const newText = text.split('\n').map(str => <p>{str}</p>);
  
  return newText;
}

class Tech extends Component {
  constructor(props) {
    super(props);

    this.state = { open: false, tech: techArray[0] }
  }

  handleClose = () => {
    this.setState({ open: false });
  }

  click = (tech) => {
    this.setState({ open: true, tech: tech });
  }

  render() {
    return (
      <section className="tech-wrapper" id="tech">
        <div className="container">
          <div className={`title-wrapper bold black`}>
            Familiar Tech
          </div>
          <div className={`description-wrapper black`}>
            Just to name a few 🧠...
          </div>
          <Grid className='grid' container
            justifyContent="center"
            alignItems="space-evenly"
            spacing={3}>
            {techArray.map((tech) => (
              <GridItem
                tech={tech} key={tech.name} onClick={() => this.click(tech)} />
            ))}
          </Grid>
          <BootstrapDialog
            onClose={this.handleClose}
            open={this.state.open}
          >
            <DialogTitle sx={{ m: 0, p: 2 }}>
              <span><IconButton
                aria-label="close"
                onClick={this.handleClose}
                sx={{
                  position: 'absolute',
                  right: 8,
                  top: 8,
                  color: (theme) => theme.palette.grey[500],
                }}
              >
                <CloseIcon />
              </IconButton><h1>{this.state.tech.name}</h1></span>
            </DialogTitle>
            <div className="dialog">
              {
                <NewlineText text={this.state.tech.details}/>
              }
            </div>
          </BootstrapDialog>
        </div>
      </section>
    );
  }

}

export default Tech;