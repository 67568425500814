import React, { Component } from 'react';
import './App.css';

import {
  BrowserRouter as Router,
  Routes,
  Route
} from "react-router-dom";

import Navbar from "../src/components/Header/Navbar"
import Main from "../src/components/Main/main"
class App extends Component {
  render() {
    return (
      <Router>
        <div>
          <Navbar />
          <Routes>
            <Route path="/" element={<Main />} />
          </Routes>
        </div>
      </Router>
    )
  }
}

export default App;
