

import React, {Component}from "react"
import './Header.css';
import Link from '@mui/material/Link';
import { styled } from '@mui/material/styles';
import Switch from '@mui/material/Switch';
var resume = require('../../../src/files/KibretWoldemichael-Resume.pdf');
// import DarkModeIcon from '@mui/icons-material/DarkMode';
/* <li>
    <span className="dark-toggle">Dark Mode<IOSSwitch sx={{ m: 1 }} defaultChecked /></span>
</li>
const IOSSwitch = styled((props) => (
    <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
  ))(({ theme }) => ({
    width: 42,
    height: 26,
    padding: 0,
    '& .MuiSwitch-switchBase': {
      padding: 0,
      margin: 2,
      transitionDuration: '300ms',
      '&.Mui-checked': {
        transform: 'translateX(16px)',
        color: '#fff',
        '& + .MuiSwitch-track': {
          backgroundColor: '#1B98F5',
          opacity: 1,
          border: 0,
        },
        '&.Mui-disabled + .MuiSwitch-track': {
          opacity: 0.5,
        },
      },
      '&.Mui-focusVisible .MuiSwitch-thumb': {
        color: '#33cf4d',
        border: '6px solid #fff',
      },
      '&.Mui-disabled .MuiSwitch-thumb': {
        color:
          theme.palette.mode === 'light'
            ? theme.palette.grey[100]
            : theme.palette.grey[600],
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
      },
    },
    '& .MuiSwitch-thumb': {
      boxSizing: 'border-box',
      width: 22,
      height: 22,
    },
    '& .MuiSwitch-track': {
      borderRadius: 26 / 2,
      backgroundColor: theme.palette.mode === 'light' ? '#E9E9EA' : '#39393D',
      opacity: 1,
      transition: theme.transitions.create(['background-color'], {
        duration: 500,
      }),
    },
  })); */

class Navbar extends Component {
    componentDidMount(){
            const selectElement = (element) =>document.querySelector(element);

            selectElement('.mobile-menu').addEventListener('click' , ()=>{
            selectElement('header').classList.toggle('active')
            })
    }
       render() { 
            return ( 
        <header>
        <div className="container2">
            <nav>
                <ul className="nav-list nav-list-mobile">
                    <li className="nav-item">
                        <div className="mobile-menu">
                            <span className="line line-top"></span>
                            <span className="line line-bottom"></span>
                        </div>
                    </li>
                    <li className="nav-item">
                    <Link 
                          href="contact"
                          underline="none" 
                          className="nav-link" 
                          color="white"
                          onClick={(e) => {
                            e.preventDefault();
                            window.open('mailto:kibretwolde@gmail.com')
                          }}>Contact</Link>
                    </li>
                </ul>
                {/* <!-- /.nav-list nav-list-mobile -->  */}
                <ul className="nav-list nav-list-larger">
                    <li className="nav-item">
                        <Link 
                          href="home" 
                          underline="none" 
                          className="nav-link" 
                          color="white"
                          onClick={(e) => {
                            e.preventDefault();
                            window.scrollTo(0, 0);
                          }}>Home</Link>
                    </li>
                    <li className="nav-item">
                    <Link 
                          href="work"
                          underline="none" 
                          className="nav-link" 
                          color="white"
                          onClick={(e) => {
                            e.preventDefault();
                            window.scrollTo(0, 600);
                          }}>Work</Link>
                    </li>
                    <li className="nav-item">
                    <Link 
                          href="tech"
                          underline="none" 
                          className="nav-link" 
                          color="white"
                          onClick={(e) => {
                            e.preventDefault();
                            document.getElementById("tech").scrollIntoView();
                          }}>Tech</Link>
                    </li>
                    <li className="nav-item">
                    <Link 
                          href={resume}
                          underline="none" 
                          className="nav-link" 
                          color="white"
                          target="_blank" rel="noopener noreferrer"
                          onClick={(e) => {
                            // e.preventDefault();
                          }}>Resume</Link>
                    </li>
                    {/* <li className="nav-item">
                        <a href="#" className="nav-link">About</a>
                    </li> */}
                    <li className="nav-item">
                    <Link 
                          href="contact"
                          underline="none" 
                          className="nav-link" 
                          color="white"
                          onClick={(e) => {
                            e.preventDefault();
                            window.open('mailto:kibretwolde@gmail.com')
                          }}>Contact</Link>
                    </li>
                </ul> 
                    
            </nav>

        </div>
    </header>
    )
    }
}
 
export default Navbar;










// import React, {Component}from "react"
// import Navlink from "./Navlink"
// import logo from "../../images/icons/app.svg"
// import search from "../../images/icons/search-icon-sm.png"
// import cart from "../../images/icons/cart-sm.png"
// import './Header.css'

// className Navbar extends Component {
//     render() { 
//         return ( 
//             <div classNameName="nav-wrapper fixed-top">
//                 <div classNameName="container">
//                     <nav classNameName="navbar navbar-toggleable-sm navbar-expand-md">
//                         <button classNameName="navbar-toggler navbar-toggler-right" type="button" data-toggle="collapse" data-target=".navbar-collapse">
//                             ☰
//                         </button>
//                         <a classNameName="navbar-brand mx-auto" href="#top"><img src={logo}/></a>

//                         <div classNameName="navbar-collapse collapse">
//                             <ul classNameName="navbar-nav nav-justified w-100 nav-fill">

//                                 <Navlink linkUrl ="#top"linkName="Mac"/>
//                                 <Navlink linkUrl ="#top"linkName="iPhone"/>
//                                 <Navlink linkUrl ="#top"linkName="iPad"/>
//                                 <Navlink linkUrl ="#top"linkName="Watch"/>
//                                 <Navlink linkUrl ="#top"linkName="TV"/>
//                                 <Navlink linkUrl ="#top"linkName="Music"/>
//                                 <Navlink linkUrl ="#top"linkName="Support"/>
//                                 <Navlink linkUrl ="#top" Image = {<img src={search}/>}/>
//                                 <Navlink linkUrl ="#top" Image = {<img src={cart}/>}/>
                                
                
                             
//                             </ul>
//                         </div>
//                     </nav>
//                 </div>
// 	        </div>
//         );     
//     }
// }
 
// export default Navbar;