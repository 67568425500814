import React, { Component } from 'react';
import AppDetails from '../AppDetails/AppDetails';

class SecondTitle extends Component {
    constructor(props) {
        super(props);
        // Don't call this.setState() here!
        this.state = { open: false };
    }

    handleClickOpen = () => {
        this.setState({ open: true })
    };

    handleClose = () => {
        this.setState({ open: false })
    };

    render() {
        const { wrapperClass, innerClassName, title, description, linkColor, titleColor, colorClass, app } = this.props;
        return (
            <div className={`${wrapperClass} col-sm-12 col-md-6`}>
                <div className={innerClassName}>
                    <div className={`title-wrapper ${titleColor}`}>
                        {title}
                    </div>
                    <div className={`description-wrapper  ${colorClass}`}>
                        {description}
                    </div>
                    <div className={`links-wrapper ${linkColor}`}>
                        <ul>
                            <li>{this.props.hidden ? <a href="top"
                                onClick={(e) => {
                                    e.preventDefault();
                                    this.handleClickOpen();
                                }}>Learn more</a> : null}</li>
                        </ul>
                    </div>
                    <AppDetails
                        open={this.state.open}
                        handleClose={this.handleClose}
                        sparked={false}
                        useful={app}
                        onscene={!app}>
                    </AppDetails>
                </div>
            </div>
        );
    }
}

export default SecondTitle;

