import React, { Component } from 'react';
import Dialog from '@mui/material/Dialog';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Slide from '@mui/material/Slide';
import MainTitle from '../Titles/MainTitle';
import './AppDetails.css';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import Grid from '@mui/material/Grid';

import Skillr from "./Skillr";
import Sparked from "./Sparked";
import Useful from "./Useful";
import OnScene from "./OnScene";

// Tech Images
var ios = require('../../../images/tech/iosdev.png');
var aws = require('../../../images/tech/aws.png');
var firebase = require('../../../images/tech/firebase.png');
var node = require('../../../images/tech/node.png');
var mongo = require('../../../images/tech/mongo.png');
var digitalocean = require('../../../images/tech/digitalocean.png');
var rabbitmq = require('../../../images/tech/rabbitmq.png');
var redis = require('../../../images/tech/redis.png');
var swiftui = require('../../../images/tech/swiftui.png');
var widgets = require('../../../images/tech/WidgetKit.png');
var appleMaps = require('../../../images/tech/applemaps.png');
var android = require('../../../images/tech/android.png');
var coredata = require('../../../images/tech/coredata.png');
var typescript = require('../../../images/tech/ts.png');
var fastlane = require('../../../images/tech/fastlane.png');
var postgresql = require('../../../images/tech/postgresql.png');
var figma = require('../../../images/tech/figma.png');

// Sparked Images
var feed = require('../../../images/projects/sparked/feed.png');
var campus = require('../../../images/projects/sparked/campus.png');
var courses = require('../../../images/projects/sparked/courses.png');
var addCourses = require('../../../images/projects/sparked/addCourses.png');
var settings = require('../../../images/projects/sparked/settings.png');

// Useful Images
var tools = require('../../../images/projects/useful/tools.png');
var mapOptions = require('../../../images/projects/useful/mapOptions.png');
var place = require('../../../images/projects/useful/place.png');
var widget = require('../../../images/projects/useful/widget.png');
var directions = require('../../../images/projects/useful/directions.png');

// OnScene Images
var files = require('../../../images/projects/onscene/files.png');
var clustering = require('../../../images/projects/onscene/cluster.png');
var measure = require('../../../images/projects/onscene/measure.png');

// Skillr Images
var explore = require('../../../images/projects/skillr/explore.png');
var profile = require('../../../images/projects/skillr/profile.png');
var chat = require('../../../images/projects/skillr/chat.png');
var skillrProfile = require('../../../images/projects/skillr/skillrProfile.png');
var skillrs = require('../../../images/projects/skillr/skillrs.png');
var skillrville = require('../../../images/projects/skillr/skillrville.png');
var skill = require('../../../images/projects/skillr/skill.png');
var postSession = require('../../../images/projects/skillr/postSession.png');
var session = require('../../../images/projects/skillr/session.png');

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function GridItem({ img, onClick }) {
  return (
    // From 0 to 600px wide (smart-phones), I take up 12 columns, or the whole device width!
    // From 600-690px wide (tablets), I take up 6 out of 12 columns, so 2 columns fit the screen.
    // From 960px wide and above, I take up 25% of the device (3/12), so 4 columns fit the screen.
    <Grid item xs={6} sm={4} md={3}>
      <Paper className='paper' elevation={1} onClick={onClick}>
        <Stack className='stack' spacing={0}>
          <span><img src={img.image} alt={img.name} /></span>
        </Stack>
      </Paper>
    </Grid>
  );
}

function TechGridItem({ tech }) {
  return (
    // From 0 to 600px wide (smart-phones), I take up 12 columns, or the whole device width!
    // From 600-690px wide (tablets), I take up 6 out of 12 columns, so 2 columns fit the screen.
    // From 960px wide and above, I take up 25% of the device (3/12), so 4 columns fit the screen.
    <Grid item xs={4} sm={3} md={2}>
      <Paper className='papers' elevation={1}>
        <Stack className='stack' spacing={0}>
          <span><img src={tech.image} alt={tech.name} /></span>
          <span>{tech.name}</span>
        </Stack>
      </Paper>
    </Grid>
  );
}

class AppDetails extends Component {
  constructor(props) {
    super(props);

    this.state = { selectedIndex : 0 }
  }

  handleClose = () => {
    this.props.handleClose();
  };

  click = (index) => {
    this.setState({ selectedIndex : index });
  }

  render() {
    const { open, skillr, sparked, useful, onscene } = this.props;

    let images;
    let title;
    let description;
    let appStoreLink;
    let selectedIndex = this.state.selectedIndex;
    let techArray;
    if (skillr) {
      title = "Skillr";
      description = "1-on-1 video chat with experts."
      appStoreLink = "https://apps.apple.com/us/app/skillr-learn-from-an-expert/id1574736206";
      images = [
        { name: "Skillr Explore tab screenshot", image: explore },
        { name: "Skillr Skillrs tab screenshot", image: skillrs },
        { name: "Skillr skill page", image: skill },
        { name: "Skillr profile tab screenshot", image: skillrProfile },
        { name: "Skillr sessions tab screenshot", image: session },
        { name: "Skillr post session tab screenshot", image: postSession },
        { name: "Skillrville screenshot", image: skillrville },
        { name: "Skillr chat tab screenshot", image: chat },
        { name: "Skillr profile tab screenshot", image: profile },

      ];
      techArray = [
        { name: "SwiftUI", image: swiftui },
        { name: "CI/CD", image: fastlane },
        { name: "Typescript", image: typescript },
        { name: "Postgresql", image: postgresql },
        { name: "Figma", image: figma }
      ];
    } else if (sparked) {
      title = "SparkEd";
      description = "Social network centered around the college campus life.";
      appStoreLink = "";
      images = [
        { name: "SparkEd campus feed screenshot", image: campus },
        { name: "SparkEd user feed screenshot", image: feed },
        { name: "SparkEd courses screenshot", image: courses },
        { name: "SparkEd add courses screenshot", image: addCourses },
        { name: "SparkEd settings screenshot", image: settings },
      ];
      techArray = [
        { name: "iOS Dev", image: ios },
        { name: "AWS", image: aws },
        { name: "Firebase", image: firebase },
        { name: "Node.js", image: node },
        { name: "MongoDB", image: mongo },
        { name: "Deployment", image: digitalocean },
        { name: "RabbitMQ", image: rabbitmq },
        { name: "Redis", image: redis },
      ];
    } else if (useful) {
      title = "Pretty Useful";
      description = "Tools for making day-to-day tasks easier."
      appStoreLink = "https://apps.apple.com/us/app/pretty-useful/id1603150073";
      images = [
        { name: "Pretty Useful map options screenshot", image: mapOptions },
        { name: "Pretty Useful tools page screenshot", image: tools },
        { name: "Pretty Useful saved place screenshot", image: place },
        { name: "Pretty Useful widget screenshot", image: widget },
        { name: "Pretty Useful ETA and directions screenshot", image: directions },

      ];
      techArray = [
        { name: "Apple Maps", image: appleMaps },
        { name: "WidgetKit", image: widgets },
        { name: "SwiftUI", image: swiftui },
        { name: "Firebase", image: firebase }
      ];
    } else {
      title = "GXP OnScene";
      description = "Real-time command to first responders in the field."
      appStoreLink = "https://apps.apple.com/us/app/gxp-onscene/id970830246";
      images = [
        { name: "GXP OnScene files page screenshot", image: files },
        { name: "GXP OnScene map clustering screenshot", image: clustering },
        { name: "GXP OnScene measuring screenshot", image: measure },
      ];
      techArray = [
        { name: "Apple Maps", image: appleMaps },
        { name: "Core Data", image: coredata },
        { name: "Firebase", image: directions },
        { name: "Android", image: android },
      ];
    }
    return (
      <div className="container">
        <Dialog
          fullScreen
          open={open}
          onClose={this.handleClose}
          TransitionComponent={Transition}
          className="dialog"
        >
          <div className="dailog-content">
            <IconButton
              color="inherit"
              onClick={this.handleClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <div className="detail-wrapper">
              <MainTitle
                titleColor="black"
                title={title}
                colorClass="black"
                description={description}
                appStoreLink={appStoreLink}
              />
              <div className="screenshots">
                <img src={images[selectedIndex].image} alt={images[selectedIndex].name} className="image" />
                <Grid className='grid' container
                  justifyContent="center"
                  alignItems="space-evenly"
                  spacing={1}
                  wrap="nowrap">
                  {images.map((img, index) => (
                    <GridItem
                      img={img} key={img.name} onClick={() => this.click(index)}>
                    </GridItem>
                  ))}
                </Grid>
              </div>
              {sparked ? <Sparked /> : null}
              {useful ? <Useful /> : null}
              {onscene ? <OnScene /> : null}
              {skillr ? <Skillr /> : null}
              <Grid className='grid' container
                justifyContent="center"
                alignItems="center"
                spacing={1}>
                {techArray.map((tech) => (
                  <TechGridItem
                    tech={tech} key={tech.name} />
                ))}
              </Grid>
            </div>
          </div>
        </Dialog>
      </div>
    );
  }
}

export default AppDetails;