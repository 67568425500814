import React, { Component } from 'react';
import MainTitle from "../Titles/MainTitle"
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import './Welcome.css';
var vino = require('../../../images/vino.JPG');
var hoops = require('../../../images/hoops.jpeg');
var pc = require('../../../images/pc.png');
var disney = require('../../../images/disney.png');
// var fashionable = require('../../../images/fashionable.MP4');

class Welcome extends Component {

    constructor(props) {
        super(props);

        this.state = { selectedIndex: 0 }
    }

    componentDidMount() {
        setInterval(() => this.setIndex(), 4000);
    }

    setIndex() {
        var index = this.state.selectedIndex;
        index++;
        if (this.state.selectedIndex === 3) {
            index = 0;
        }
        this.setState({ selectedIndex: index})
    }

    componentWillUnmount() {
        clearInterval(this.interval);
    }

    click = (index) => {
        this.setState({ selectedIndex: index });
    }

    render() {

        let selectedIndex = this.state.selectedIndex;

        let images = [
            { name: "Kibret drinking red wine.", image: vino, description: "I enjoy myself." },
            { name: "Kibret playing basketball at San Diego State", image: hoops, description: "I eat, sleep, and breathe basketball." },
            { name: "Kibret's Gaming PC", image: pc, description: "I love video games." },
            { name: "Kibret at Disney", image: disney, description: "I am always smiling and laughing." }
        ];
        return (
            <section className="second-hightlight-wrapper">
                <MainTitle titleColor="black" title="Welcome" colorClass="black" description="My name is Kibret Woldemichael and I am an iOS developer."
                />
                <p>{images[selectedIndex].description}</p>
                {selectedIndex !== 5 ? <img src={images[selectedIndex].image} alt={images[selectedIndex].name} className="heroimage" /> : <video alt={images[selectedIndex].name} className="heroimage" src={images[selectedIndex].image} autoPlay muted></video> }
            </section>
        );
    }
}
export default Welcome;