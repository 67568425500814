import React, { Component } from 'react';
import './AppDetails.css';
// import directions from "../../../images/directions.png";

function Useful() {
    return (
        <div className="about">
            <h1>The Story</h1>
            <p>This originally started out as me designing a "Resume" app that was literally going to be my resume in iOS app form. My plan was to use this as a playground to experiment with different APIs and just build or integrate anything I felt like building. The first builds I uploaded to Apple Connect were rejected because the app was too niche and could basically be put in a static website. Pretty understandable but all good. Easy pivot was to put the emphasis on the tools and APIs that I would be building on in the app and embed my profile into that.
            </p><br></br>
            <h1>The App</h1>
            <p>The first and only tool that is currently in the app is the Maps tool. One thing I hate about the Apple Maps app is that I have to open the app and tap on one of my saved places or search for a place every time in order to get my ETA. So I thought it would be pretty useful (Yes I literally named the app after this thought) to have a widget in the home screen that was a single tap away from retrieving this information for me. Building out widgets actually requires the use of SwiftUI so it was nice to get an opportunity to get started with learning that as well.
            </p><br></br>
            <p>I have a couple other pretty useful tools I have in mind for this application that I will be building on moving forward but basically this is my version of everyone else's GitHub tutorial projects. No copy paste on this side. I'm all about building things with real world functionality and honestly it is hard for me to even go through with implementing cookie cutter type stuff.
            </p><br></br>
            <h1>Tech Used</h1>
        </div>
    );
}

export default Useful;