import React from 'react';
import './AppDetails.css';
// import directions from "../../../images/directions.png";

function Skillr() {
    return (
        <div className="about">
            <p>First start up!
            </p><br></br>
            <h1>Tech Used</h1>
        </div>
    );
}

export default Skillr;